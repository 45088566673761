























import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {},
})
export default class PhaseNftInfoCard extends Vue {
  walletStore = walletStore
}
